import { makeStyles } from '@material-ui/styles'
import ProgressBar from 'core/components/progress/ProgressBar'
import { middleRight } from 'core/elements/menu/defaults'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import PropTypes from 'prop-types'
import { identity } from 'ramda'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  resourceUsageTable: {
    display: 'grid',
    gridTemplateColumns: '60px max-content',
    gridGap: '8px',
  },
  rightAlign: {
    textAlign: 'right',
    ...theme.typography.caption1,
  },
  value: {
    whiteSpace: 'nowrap',
    paddingRight: 4,
    display: ({ valueOff }) => (!valueOff ? 'grid' : 'none'),
    gridTemplateColumns: '1fr 4px 1fr 35px',
    gridGap: '4px',
  },
  percent: {
    width: 30,
  },
  percentGrid: {
    padding: theme.spacing(0.5, 0),
  },
}))

const defaultTooltipProps = {
  ...middleRight,
  origin: 'left top',
}

const ResourceUsageTable = ({
  valueOff = false,
  label,
  valueConverter,
  usedText,
  units,
  stats = {},
  precision,
  width = 138,
  tooltipProps = defaultTooltipProps,
}) => {
  const classes = useStyles({ valueOff })
  const { current = 0, max = 0, percent = max === 0 ? 0 : (current / max) * 100 } = stats

  const curStr = valueConverter(current).toFixed(precision)
  const maxStr = valueConverter(max).toFixed(precision)
  return (
    <Tooltip
      {...tooltipProps}
      message={`${curStr} ${units} of ${maxStr} ${units} (${Math.round(percent)}%) ${usedText}`}
    >
      <div className={classes.resourceUsageTable}>
        {label && <Text variant="body2">{label}:</Text>}
        <span className={classes.percentGrid}>
          <ProgressBar width={width} variant="health" percent={percent} showPercent={false} />
        </span>
      </div>
    </Tooltip>
  )
}

ResourceUsageTable.propTypes = {
  valueConverter: PropTypes.func,
  precision: PropTypes.number,
  label: PropTypes.string,
  usedText: PropTypes.string,
  units: PropTypes.string,
  stats: PropTypes.shape({
    current: PropTypes.number,
    max: PropTypes.number,
    percent: PropTypes.number,
  }),
}

ResourceUsageTable.defaultProps = {
  stats: { current: 0, max: 0, percent: 0 },
  valueConverter: identity,
  usedText: 'used',
  units: '',
  precision: 2,
}

export default ResourceUsageTable
