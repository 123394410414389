import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { assocPath, pipe } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { IClusterAddon, AddonOverrideParam } from './model'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { getAddonParamFields } from './edit-cluster-page/viewHelpers'

export const parseClusterAddonParams = (params: AddonOverrideParam[]) => {
  if (!params) return {}
  return params.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})
}

export const addonVersionsSelector = getDataSelector<DataKeys.AddonVersions>(DataKeys.AddonVersions)

export const clusterAddonsSelector = createSharedSelector(
  getDataSelector<DataKeys.ClusterAddons>(DataKeys.ClusterAddons, ['clusterId']),
  (rawClusterAddons): IClusterAddon[] => {
    return rawClusterAddons.map((addon) => {
      const params = parseClusterAddonParams(addon.spec?.override?.params)
      const version = addon.spec?.version
      return {
        ...addon,
        id: addon.metadata?.uid,
        name: addon.metadata?.name,
        clusterId: addon.spec?.clusterID,
        type: addon.spec?.type,
        params,
        version,
        healthy: addon.status?.healthy,
        phase: addon.status?.phase,
        isConfigurable:
          !isNilOrEmpty(addon.spec?.override) && getAddonParamFields(addon.spec?.type) !== null,
        isEnabled: !addon?.metadata?.deletionTimestamp,
      }
    })
  },
)

export const makeClusterAddonsSelector = (defaultParams = {} as SortConfig) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(clusterAddonsSelector, selectParams, (clusterAddons, params) => {
    const { orderBy, orderDirection } = params
    return pipe<IClusterAddon[], IClusterAddon[]>(createSorter({ orderBy, orderDirection }))(
      clusterAddons,
    )
  })
}

export const monitoringAddonsSelector = createSharedSelector(
  getDataSelector<DataKeys.MonitoringAddons>(DataKeys.MonitoringAddons),
  (rawMonitoringAddons): IClusterAddon[] => {
    return rawMonitoringAddons.map((addon) => ({
      ...addon,
      id: addon.metadata?.uid,
      name: addon.metadata?.name,
      clusterId: addon.spec?.clusterID,
      type: addon.spec?.type,
      params: parseClusterAddonParams(addon.spec?.override?.params),
      version: addon.spec?.version,
      healthy: addon.status?.healthy,
      phase: addon.status?.phase,
      isConfigurable:
        !isNilOrEmpty(addon.spec?.override) && getAddonParamFields(addon.spec?.type) !== null,
      isEnabled: !addon?.metadata?.deletionTimestamp,
    }))
  },
)

export const kubevirtAddonsSelector = createSharedSelector(
  getDataSelector<DataKeys.KubevirtAddons>(DataKeys.KubevirtAddons),
  (rawKubevirtAddons): IClusterAddon[] => {
    return rawKubevirtAddons.map((addon) => ({
      ...addon,
      id: addon.metadata?.uid,
      name: addon.metadata?.name,
      clusterId: addon.spec?.clusterID,
      type: addon.spec?.type,
      params: parseClusterAddonParams(addon.spec?.override?.params),
      version: addon.spec?.version,
      healthy: addon.status?.healthy,
      phase: addon.status?.phase,
      isConfigurable:
        !isNilOrEmpty(addon.spec?.override) && getAddonParamFields(addon.spec?.type) !== null,
      isEnabled: !addon?.metadata?.deletionTimestamp,
    }))
  },
)

export const networkingAddonsSelector = createSharedSelector(
  getDataSelector<DataKeys.NetworkingAddons>(DataKeys.NetworkingAddons),
  (rawNetworkingAddons): IClusterAddon[] => {
    return rawNetworkingAddons.map((addon) => ({
      ...addon,
      id: addon.metadata?.uid,
      name: addon.metadata?.name,
      clusterId: addon.spec?.clusterID,
      type: addon.spec?.type,
      params: parseClusterAddonParams(addon.spec?.override?.params),
      version: addon.spec?.version,
      healthy: addon.status?.healthy,
      phase: addon.status?.phase,
      isConfigurable:
        !isNilOrEmpty(addon.spec?.override) && getAddonParamFields(addon.spec?.type) !== null,
      isEnabled: !addon?.metadata?.deletionTimestamp,
    }))
  },
)

export const metal3AddonsSelector = createSharedSelector(
  clusterAddonsSelector,
  (allAddons): IClusterAddon[] => allAddons.filter((addon) => addon.type === 'metal3'),
)

export const clusterAddonsByClusterSelector = createSharedSelector(
  clusterAddonsSelector,
  (rawAddons) =>
    rawAddons.reduce((accum, addon) => {
      const { clusterId } = addon
      const existingAddons = accum[clusterId] || []
      const addons = [...existingAddons, addon]
      return assocPath([clusterId], addons, accum)
    }, emptyObj),
)

// Capi clusters use cluster name
export const clusterAddonsByClusterNameSelector = createSharedSelector(
  clusterAddonsSelector,
  (rawAddons) =>
    rawAddons.reduce((accum, addon) => {
      const clusterName = addon?.metadata?.labels?.clusterName || ''
      const existingAddons = accum[clusterName] || []
      const addons = [...existingAddons, addon]
      return assocPath([clusterName], addons, accum)
    }, emptyObj),
)

export const monitoringAddonsByClusterSelector = createSharedSelector(
  monitoringAddonsSelector,
  (rawAddons) =>
    rawAddons.reduce((accum, addon) => {
      const { clusterId } = addon
      return assocPath([clusterId], addon, accum)
    }, emptyObj),
)

export const kubevirtAddonsByClusterSelector = createSharedSelector(
  kubevirtAddonsSelector,
  (rawAddons) =>
    rawAddons.reduce((accum, addon) => {
      const { clusterId } = addon
      return assocPath([clusterId], addon, accum)
    }, emptyObj),
)

export const networkingAddonsByClusterSelector = createSharedSelector(
  networkingAddonsSelector,
  (rawAddons) =>
    rawAddons.reduce((accum, addon) => {
      const { clusterId } = addon
      return assocPath([clusterId], addon, accum)
    }, emptyObj),
)

export const clusterVersionsSelector = createSharedSelector(
  getDataSelector<DataKeys.ClusterVersions>(DataKeys.ClusterVersions),
  (rawClusterVersions): any[] => {
    return rawClusterVersions.map((rawClusterVersion) => {
      const latest = rawClusterVersion?.metadata?.labels?.['pmk.pf9.io/latest'] === 'true'
      return {
        ...rawClusterVersion,
        latest: latest,
        clusterCreationAllowed:
          rawClusterVersion?.phase === 'EarlyAccess' ||
          (rawClusterVersion?.phase === 'Active' && latest) ||
          (rawClusterVersion?.phase === 'Deprecated' && latest),
        // Cluster can be upgraded to this version from a lower version or patch
        clusterUpgradePossible:
          rawClusterVersion?.phase === 'EarlyAccess' ||
          rawClusterVersion?.phase === 'Active' ||
          (rawClusterVersion?.phase === 'Deprecated' && latest),
      }
    })
  },
)
export const eksAddonsSelector = createSharedSelector(
  getDataSelector<DataKeys.EksAddons>(DataKeys.EksAddons),
  (eksAddons): any[] => {
    return eksAddons
  },
)
