import { makeStyles } from '@material-ui/styles'
import Alert from 'core/components/Alert'
import SubmitButton from 'core/components/buttons/SubmitButton'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Text from 'core/elements/Text'
import downloadFile from 'core/utils/downloadFile'
import React, { useMemo, useState } from 'react'
import Modal from 'core/elements/modal'
import ApiClient from 'api-client/ApiClient'
import Button from 'core/elements/button/Button'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { isQbertCluster } from './helpers'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { generateKubeConfig } from './kubeconfig'
import { RootState } from 'app/store'

const { sunpike } = ApiClient.getInstance()
const maxDialogWidth = 800

const downloadKubeconfig = async (cluster, authMethod, userCreds, isSsoToken) => {
  if (isQbertCluster(cluster)) {
    const result = await generateKubeConfig(cluster.uuid, authMethod, userCreds, isSsoToken)
    if ('kubeconfig' in result) {
      return { kubeconfig: result.kubeconfig, error: null }
    }
    return { kubeconfig: null, error: result.error }
  }
  // For token auth method, only need to send X-Auth-Token in API request header
  const body = authMethod === 'password' ? userCreds : {}
  const { response, error } = await sunpike.downloadKubeconfig(
    cluster?.name,
    body,
    cluster?.namespace,
  )
  return { kubeconfig: response, error }
}

const KubeconfigDownloadModal = ({ cluster, open = false, onClose }) => {
  if (!cluster) return null

  const options = useMemo(() => {
    const list = [
      {
        value: 'token',
        label: 'Token',
      },
      {
        value: 'password',
        label: 'Password',
      },
    ]
    // CAPI clusters don't support certificate token
    if (isQbertCluster(cluster)) {
      list.push({
        value: 'certificate',
        label: 'Certificate',
      })
    }
    return list
  }, [cluster])

  const classes = useStyles()
  const [authMethod, setAuthMethod] = useState('token')
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [kubeConfigFile, setKubeConfigFile] = useState()
  const [toPreviewKubeconfig, setToPreviewKubeconfig] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { isSsoToken } = session

  const handleSubmit = async (params) => {
    setErrorMessage(null)
    setSubmitting(true)
    const { username, password } = params
    const userCreds = { username, password }

    const { kubeconfig, error } = await downloadKubeconfig(
      cluster,
      authMethod,
      userCreds,
      isSsoToken,
    )

    if (error) {
      setSubmitting(false)
      return setErrorMessage(typeof error === 'string' ? error : 'Error getting the Kubeconfig')
    }
    setKubeConfigFile(kubeconfig)

    if (kubeconfig) {
      downloadFile({
        filename: `${cluster?.name}-kubeconfig.yaml`,
        contents: kubeconfig,
      })
    }

    setSubmitting(false)
  }

  return (
    <Modal
      open={open}
      title="Download Kubeconfig"
      onClose={onClose}
      maxWidth={maxDialogWidth}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      <ValidatedForm onSubmit={handleSubmit} fullWidth elevated={false}>
        <div className={classes.formBody}>
          <div className={classes.authMethod}>
            <Text variant="caption1">Authentication Method</Text>
            <RadioFields
              id="method"
              options={options}
              value={authMethod}
              onChange={(value: string) => setAuthMethod(value)}
            />
          </div>

          {authMethod === 'password' && (
            <>
              <TextField id="username" label="Enter your Username" required />
              <TextField id="password" label="Enter your Password" type="password" required />
              <br />
            </>
          )}
          {!!errorMessage && (
            <div className={classes.errorContainer}>
              <Alert variant="error" message={errorMessage} />
            </div>
          )}
          <div className={classes.buttonBar}>
            <SubmitButton type="submit" loading={submitting}>
              {authMethod !== 'password' ? 'Download Config' : 'Validate + Download Config'}
            </SubmitButton>
            <CheckboxField
              id="previewKubeconfig"
              label="Preview Kubeconfig"
              onChange={(value) => setToPreviewKubeconfig(value)}
              value={toPreviewKubeconfig}
            />
          </div>
          <Alert
            variant={authMethod !== 'password' ? 'primary' : 'warning'}
            title="Note:"
            message={
              authMethod === 'token'
                ? 'Token authentication is a preferred method for downloading kubeconfig. The kubeconfig will remain valid for the next 24 hours.'
                : authMethod === 'certificate'
                ? 'Certificate authentication is a preferred method for downloading kubeconfig. The kubeconfig will remain valid as long as the certificate is valid.'
                : 'Password authentication is less secure than token authentication, but the kubeconfig will remain functional for as long as the username and password are valid.'
            }
          />
        </div>
        <br />
        {!!kubeConfigFile && toPreviewKubeconfig && (
          <CodeMirror
            id="kubeconfigYaml"
            showCopyButton
            label={cluster?.name}
            maxHeight={400}
            value={kubeConfigFile}
            className={classes.overflowScroll}
          />
        )}
      </ValidatedForm>
    </Modal>
  )
}

export default KubeconfigDownloadModal

const useStyles = makeStyles((theme) => ({
  formBody: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 16,
    justifyItems: 'start',
  },
  authMethod: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gap: 32,
  },
  errorContainer: {
    paddingLeft: '12px',
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 8,
  },
  overflowScroll: {
    maxWidth: maxDialogWidth - 60,
    overflowX: 'auto',
  },
}))
