import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { assocPath, pluck } from 'ramda'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { CapiResourceKind } from '../clusters/aws/capi/model'
import { createSelector } from 'reselect'
import { allKey, noneKey } from 'app/constants'
import { IK8sNodeSelector } from './model'

export const k8sNodesSelector = createSharedSelector(
  getDataSelector<DataKeys.K8sNodes>(DataKeys.K8sNodes),
  (k8sNodes) => {
    return k8sNodes.map((node) => {
      return {
        ...node,
        ready: node?.status?.conditions?.find((cond) => cond.type === 'Ready')?.status,
      }
    })
  },
)
export const eksCapik8sNodesSelector = createSharedSelector(
  k8sNodesSelector,
  getDataSelector<DataKeys.CapiClusters>(DataKeys.CapiClusters),
  (k8sNodes, clusters): IK8sNodeSelector[] => {
    const eksClusterIds = clusters
      .filter(
        (cluster) =>
          cluster.spec?.infrastructureRef?.kind === CapiResourceKind.AWSManagedControlPlane, // identify EKS clusters
      )
      .map((cluster) => cluster?.metadata?.uid)
    return k8sNodes
      .filter((node) => eksClusterIds.includes(node.clusterId))
      .map((node) => {
        return {
          ...node,
          uuid: node?.metadata?.uid,
          name: node?.metadata?.name,
          clusterName: node?.metadata?.annotations?.['cluster.x-k8s.io/cluster-name'],
          conditions: node?.status?.conditions,
          operatingSystem: node?.status?.nodeInfo?.osImage,
          nodeKubeVersion: node?.status?.nodeInfo?.kubeletVersion,
        }
      })
  },
)

export const k8sNodesByClusterIdAandNodeNameSelector = createSharedSelector(
  k8sNodesSelector,
  (k8sNodes) => {
    return k8sNodes.reduce((accum, node) => {
      const { name, clusterId } = node
      return assocPath([clusterId, name], node, accum)
    }, {})
  },
)
export const k8sNodesByClusterIdAndProviderIdSelector = createSharedSelector(
  k8sNodesSelector,
  (k8sNodes) => {
    return k8sNodes.reduce((accum, node) => {
      const { clusterId } = node
      const providerId =
        node?.spec?.providerID || node?.metadata?.labels?.['capi.pf9.io/instanceID']
      return assocPath([clusterId, providerId], node, accum)
    }, {})
  },
)

export const makeParamsEksNodesSelector = (
  defaultParams = {
    clusterId: null,
  },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(eksCapik8sNodesSelector, selectParams, (nodes, params) => {
    const { clusterId } = params

    const filterByCluster = (node) => {
      if (clusterId === allKey) {
        return true
      } else if (clusterId === noneKey) {
        return false
      }
      return node.clusterId === clusterId
    }

    return nodes.filter(filterByCluster)
  })
}
